const speakerData = [
    {
        id : "a0",
        title : "Imelda Usoro Olaoye",
        position : "Founder, Business Women Hub",
        company : "Managing Partner, Thinkmint Nigeria/Europe",
        imageUrl : "imeldaolaoye.webp"
    },
    {
        id : "a1",
        title : "Mrs Kikelomo Atanda-Owo",
        position : "CEO",
        company : "Z-edge Consulting",
        imageUrl : "kikelomoatanda.png"
    },
    {
        id : "a2",
        title : "Gusi Tobby Lordwilliams",
        position : "Social Impact Strategist,",
        company : "Communitcations Expert",
        imageUrl : "gusitobby.png"
    },
    {
        id : "a3",
        title : "Ufoma Okerentugba-Thompson",
        position : "Senior Consultant",
        company : "Centafold Consult",
        imageUrl : "ufomathompson.png"
    },
    {
        id : "a4",
        title : "Itee Akrasi",
        position : "CEO",
        company : "Teerasi Empire",
        imageUrl : "iteeakrasi.png"
    },
    // {
    //     id : "2",
    //     title : "Yeside Aminat Olasimbo",
    //     position : "Special Assistant on Women Affairs to the Speaker House of Representatives",
    //     company : "Federal Republic of Nigeria",
    //     imageUrl : "yesideaminat.png"
    // },
    // {
    //     id : "3",
    //     title : "Mrs Ini Abimbola1",
    //     position : "Vice President",
    //     company : "Sustainability Professionals Institute of Nigeria",
    //     imageUrl : "iniabimbola.png"
    // },
    // {
    //     id : "4",
    //     title : "Abiola Adelana",
    //     position : "Head Tourism and creative Art Business",
    //     company : "Sterling Bank plc",
    //     imageUrl : "abiolaadelana.png"
    // },
    // {
    //     id : "5",
    //     title : "Gusi Tobby Lordwilliams",
    //     position : "Social Impact Strategist,",
    //     company : "Communitcations Expert",
    //     imageUrl : "gusitobby.png"
    // },
    // {
    //     id : "6",
    //     title : "Olubunmi Ebhomenye",
    //     position : "Director",
    //     company : "Talent Academy, Flour Mills Nigeria",
    //     imageUrl : "olubunmi.png"
    // },
    // {
    //     id : "7",
    //     title : "Ubong Ita",
    //     position : "CEO & Co-Founder",
    //     company : "Konoaccountant / etiaba",
    //     imageUrl : "ubongita.png"
    // },
    // {
    //     id : "8",
    //     title : "Ella Ezeadilieje PhD",
    //     position : "Story Teller, Brand Strategist Influencer",
    //     company : "",
    //     imageUrl : "eze.png"
    // },
    // {
    //     id : "9",
    //     title : "Ayo Bankole",
    //     position : "Strategy & Transformation Professional",
    //     company : "Caladium Consulting",
    //     imageUrl : "ayobankole.png"
    // },
    // {
    //     id : "10",
    //     title : "Sarah Samuel Amana",
    //     position : "Personal Finance Expert",
    //     company : "",
    //     imageUrl : "sarah.png"
    // },
    // {
    //     id : "11",
    //     title : "Hussaina Yakubu",
    //     position : "Motivational Speaker, Founder",
    //     company : "Hussy Foods",
    //     imageUrl : "hussaina.png"
    // },
    // {
    //     id : "12",
    //     title : "Mosunmola Nicole E.",
    //     position : "Chief Experience Officer",
    //     company : "IPC-Event Nigeria",
    //     imageUrl : "mosunmola.png"
    // },
    // {
    //     id : "13",
    //     title : "Linda Ogbugbua",
    //     position : "Head, Digital Advert Business Day/SME Business Expert",
    //     company : "IPC-Event Nigeria",
    //     imageUrl : "linda.png"
    // },
    // {
    //     id : "14",
    //     title : "Emem Attah",
    //     position : "MC",
    //     company : "",
    //     imageUrl : "emem.png"
    // },

]


export default speakerData;